@import "./core/font";
@import "./core/mixin";

.page-product-dt {
    .breadcrumb {
        width: fit-content;
        margin: 0 auto;
    }
}

.search_key-prod {
    @include fz-16;
    @include mb(2.4rem);
}

.key_word_prod {
    font-weight: 700;
    @include fz-20;
}

// fix review KH //
.rv .rvRtSlider {
    @include mb(1rem);
}

@include min(500px) {
    .rv .rv-rt-content-wr .text {
        height: 18rem;

        @include max(1090px) {
            height: 13rem;
        }

        overflow: hidden auto;
        padding-right: .5rem;
    }
}

@include max(1700px) {
    .rv-lf {
        @include flex-col;
        justify-content: end;
    }
}

@include max(1199px) {
    .rv-lf {
        display: none;
    }

    .rv-rt {
        width: 100%;
    }

    .rv .wave-bg {
        display: none;
    }
}